import React from 'react';
import {
  Input
} from 'reactstrap'
export default function GlobalFilter({filter, setFilter}) {
  return (
    <div>
      <Input className="form-control" value={filter || ''} placeholder="Search.." onChange={(e) => setFilter(e.target.value)} />
      {/* <input type="text" value={filter || ''} onChange={(e) => setFilter(e.target.value)} /> */}
    </div>
  )
};
