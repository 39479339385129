import LOGO from "../../../images/logo_new.png";

const Header = () => {
  return(
    <>
      <div className="sidebar-header">
        <div>
          <img src={LOGO} className="logo-icon" width={'120px'} alt="logo" />
        </div>
        {/* <div>
          <h4 className="logo-text">Reelive</h4>
        </div> */}
        <div className="toggle-icon ms-auto"> <i className="bi bi-list"></i>
        </div>
      </div>
    </>
  )
};

export default Header;