import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promise from 'redux-promise';
import createDebounce from 'redux-debounced';

import {
  login,
  mediaList,
  mediaManagement,
} from './ducks';

import actionTypes from './ducks/login/action-types';

const unorderedReducers = {
  [login.REDUCER_KEY]: login.reducer,
  [mediaList.REDUCER_KEY]: mediaList.reducer,
  [mediaManagement.REDUCER_KEY]: mediaManagement.reducer,
};

const orderedReducers = Object.keys(unorderedReducers)
  .sort()
  .reduce((hash, key) => ({
    ...hash,
    [key]: unorderedReducers[key],
  }), {});

export function configureStore(initialState = {}) {
  const reducer = combineReducers(orderedReducers);

  const rootReducer = (state, action) => {
    // in case of Logout restore store
    if (action.type === actionTypes.RESET_STORE) {
      // eslint-disable-next-line
      state = undefined;
    }
    return reducer(state, action);
  };

  const middleware = [
    // fuxA(), // google analytics, mixpanel...future use
    createDebounce(),
    promise,
    thunkMiddleware, // lets us dispatch() functions
  ];

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return store;
}

export default configureStore;