import React from 'react';
import { Link } from 'react-router-dom';
import {
  House,
  FileEarmarkMusic,
  PersonSquare,
} from 'react-bootstrap-icons';

const menuClick = (event) => {
  // event.preventDefault();
  const targetClass = event.currentTarget.className;
  const subnav = event.currentTarget.parentElement.querySelector('.subnav-wrapper');
  if (targetClass.indexOf('toggle-menu') > -1) {
    if (subnav.className.indexOf('active') > -1) {
      subnav.className = 'subnav-wrapper';
    } else {
      subnav.className = 'active subnav-wrapper';
    }
  }
}

const MenuList = (menuList) => {
  return(
    <ul className="metismenu mm-show" id="menu">
      {menuList.menuList.map((menu)=> {
        const {
          icon,
          title,
          linkTo,
          subNav,
        } = menu;
        return (
        <li key={title}>
          <Link to={linkTo} aria-expanded="true" className={subNav ? 'toggle-menu side-main-nav' : 'side-main-nav'} onClick={menuClick}>
            {icon === 'House' && <House />}
            {icon === 'FileEarmarkMusic' && <FileEarmarkMusic />}
            {icon === 'PersonSquare' && <PersonSquare />}
            <div className="menu-title">{title}</div>
          </Link>
          {subNav
          ?
          <ul className="subnav-wrapper">
            {
              subNav.map(menu=> {
                const {
                  title,
                  linkTo,
                } = menu;
                return (
                <li key={title}>
                  <Link to={linkTo} aria-expanded="true">
                    - <div className="menu-title">{title}</div>
                  </Link>
                  </li>
              )})
            }
          </ul>
          :
          ''}
        </li>
        )
      })}
    </ul>
  );
}

export default MenuList;