export const createAction = type => (payload = {}) => ({
  type,
  payload: { ...payload },
});

export const loading = type => payload => ({
  type,
  payload: { ...payload },
});

export const loadSuccess = type => (name = 'data', list, other) => ({
  type,
  payload: {
    loading: false,
    error: null,
    [name]: list,
    ...other,
  },
});
export const loadError = type => error => ({
  type,
  payload: {
    loading: false,
    error,
  },
});

export const actionTypeExists = actionTypes => Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

export default {
  loading,
  loadError,
  loadSuccess,
  actionTypeExists,
};
