import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';

export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

// model login method
export const login = (username, password) => (dispatch) => {
  
  // if (username == 'admin' && password == 'admin') {
  //   // dispatch(boundSuccess({
  //   //   data: {id: '123'},
  //   //   loading: false,
  //   // }));
  //   sessionStorage.setItem('authToken', '123abc');
  //   dispatch(dataChanged({
  //     authenticatedSession: true,
  //     // [constants.AUTH_TOKEN]: '123abc'
  //   }));
  // } else {
  //   dispatch(boundError({data: 'invalid credentials', loading: false}));
  // }
  // http post request
  // axios.post([constants.LOGIN_API], {
  //   // options
  //   username: username,
  //   password: password,
  //   usertype:"user"
  // },
  // {
  //   headers: {
  //     // 'Access-Control-Allow-Origin': '*',
  //   },
  // })
  // .then(function (response) {
  //   console.log('login data', response.data);
  //   const data = response.data;

  //   // set local storage
  //   if (data.responseData) {
  //     localStorage.setItem('userData', JSON.stringify(data.responseData[0]));
  //   }

  //   if (data.status != 200) {
  //     dispatch(dataChanged({
  //       data: data.errorMessage,
  //       loading: false
  //     }));
      
  //   } 
  //   // else {
  //   //   dispatch(dataChanged({
  //   //     data: data.errorMessage,
  //   //     loading: false
  //   //   }));
  //   // }
    

  //   // dispatch(boundSuccess({data: response, loading: false}));
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   dispatch(boundError({data: error, loading: false}));
  // });
}

export const showError = (errorMsg) => (dispatch) => {
  dispatch(dataChanged({
    data: errorMsg,
  }));
}

export const showLogoutSuccess = (errorMsg) => (dispatch) => {
  dispatch(dataChanged({
    data: errorMsg,
  }));
}

export const logout = () => (dispatch) => {
    sessionStorage.setItem('authToken', '');
    dispatch(dataChanged({
      [constants.AUTHENTICATED_SESSION]: false,
      // [constants.AUTH_TOKEN]: '123abc'
    }));
}
