import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {Container,Row,Col,Card,CardBody, Modal, ModalBody,
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';

import { mediaTable } from '../../config';
import { mediaTableConfig } from '../../ducks/media-list/constants';
import CustomTable from '../../components/table';
// import TableList from './tableList';
import MediaEditForm from './mediaEditForm';
import MediaDeleteForm from './mediaDeleteForm';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import * as mediaListSelectors from '../../ducks/media-list/selectors';
import {
  DeleteForm,
} from './components';
import {
  getMediaList,
  deleteAudioForm,
  toggleDeleteForm,
} from '../../ducks/media-list/index';
import './index.scss';
const MediaList = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const auth = useContext(AuthContext);
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getMediaList();
  }, []);
  const {
    data,
    authenticatedSession,
    showDeleteForm,
    selectedRowId,
    actions,
    loading,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  // const toggleDeleteForm = (data) => {
  //   const {
  //     actions
  //   } = props;
  //   // actions.deleteAudioForm(data);
  //   setDeleteModal(!deleteModal)
  // }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content">
        <div className="profile-cover bg-dark"></div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3 className="media-list-header">
                    {"Media List"}
                  </h3>
                  <div className="best-seller-table">
                    <Modal isOpen={showDeleteForm} className="delete-form">
                      <ModalBody>
                        <DeleteForm {...props} />
                      </ModalBody>
                    </Modal>
                    <div className="item  mb-3">
                      <div className="product-list">
                        {/* mediaTableConfig */}
                        <CustomTable
                        data={data}
                        onDeleteClick={actions.toggleDeleteForm}
                        options = {mediaTableConfig.options}
                        columns={mediaTableConfig.columns} />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

MediaList.propTypes = {
  username: PropTypes.string,
};

MediaList.defaultProps = {
  username: '',
};

const mapStateToProps = state => ({
  ...state.mediaList,
  data: mediaListSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMediaList,
    deleteAudioForm,
    toggleDeleteForm,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
