import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { LinkContainer } from 'react-router-bootstrap';
export class Nav extends React.Component {
  static propTypes = {
    // username: PropTypes.string,
  }
  static defaultProps = {
    // username: '',
  }
  render() {
    return (
      <>
        {/* <div className="wrapper">
          <h1>Nav</h1>
          <ul className="nav-links">
            <Link to="/">Home</Link>
            <Link to="login">Login</Link>
            <Link to="about">About</Link>
          </ul>
        </div> */}
        <Container className="p-3">
          <Container className="p-5 mb-4 bg-light rounded-3">
            <h2>
              <ButtonToolbar className="custom-btn-toolbar">
                <LinkContainer to="/">
                  <Button>Home</Button>
                </LinkContainer>
                <LinkContainer to="/login">
                  <Button>Login</Button>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Button>About</Button>
                </LinkContainer>
              </ButtonToolbar>
            </h2>
          </Container>
        </Container>
      </>
    )
  }
}

export default Nav;