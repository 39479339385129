import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);


export const getMediaList = () => (dispatch, getState) => {
  const currentState = getState();

  const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || null;
  // const updatedData = beforeSubmit(data, currentState.mediaManagement);
  // http post request
  dispatch(dataChanged({loading: true, authenticatedSession: true}));
  const getApi = constants.MEDIA_LIST_API;
  const options = {
    // headers: {
    //   'authorization': userData && userData.token,
    //   'Access-Control-Allow-Origin': '*',
    // },
  };

  axios.get(getApi,options)
  .then(function (response) {
    const data = response.data;
    console.log(data);
    if (data.errorMessage) {
      dispatch(
        dataChanged({
          [constants.DATA_ERROR]: data.errorMessage,
          loading: false
        })
      )
    } else {
      dispatch(
        dataChanged({
          [constants.DATA]: data.responseData[0].data,
          loading: false
        })
      );
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
    const { status } = error.response;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({loading: false, authenticatedSession: false}));
    } else {
      dispatch(dataChanged({loading: false}));
    }
    // dispatch(boundError({data: error, loading: false}));
  });
};

export const toggleDeleteForm = (toggle, data) => (dispatch) => {
  // dispatch(boundModalLoading({ [constants.MODAL_LOADING]: false }));
  dispatch(dataChanged({
    [constants.SHOW_DELETE_FORM]: toggle,
    [constants.SELECTED_ROW_ID]: data ? data.id : null,
  }));
};

export const deleteAudioForm = (id) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  // const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || null;
  let api = [constants.MEDIA_LIST_API];
  api = api + `/${id}`;
  axios.delete(api,
    {
      // headers: {
      //   // 'Access-Control-Allow-Origin': '*',
      //   'authorization': userData && userData.token,
      // },
    }
  )
  .then(
    function (response) {
      const data = response.data;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          loading: false,
          [constants.SHOW_DELETE_FORM]: false,
          [constants.SELECTED_ROW_ID]: '',
        }));
      }
      dispatch(getMediaList());
    }
  )
  .catch(
    function (error) {
      const { status } = error && error.response || 0;
     if (status == UN_AUTHERIZED_CODE) {
       //redirect to login, clear local storage
      //  localStorage.removeItem('userData');
       dispatch(dataChanged({loading: false}));
     } else {
       dispatch(dataChanged({loading: false}));
     }
   }
  )
};
