import PropTypes from 'prop-types';
import React from 'react';

export const MediaEditForm = (props) => {
  console.log(props);
  return (
    <div>MediaEditForm</div>
  )
}

MediaEditForm.propTypes = {
  user: PropTypes.bool
}

export default (MediaEditForm)