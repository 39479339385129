import React, { useMemo } from 'react';
import { 
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTimes, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

import GlobalFilter from './components/globalFilter';
import {Table,Row,Col,FormGroup,Label,Input,Pagination,PaginationItem,PaginationLink} from 'reactstrap';
import DEFAULT_IMAGE_THUMBNAIL from '../../images/media_logo1.jpeg';
import { PUBLIC_IMAGE_URL } from '../../config';
import './index.scss';

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// // Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter
// }) {
//   debugger;
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{" "}
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: "1.1rem",
//           border: "0"
//         }}
//       />
//     </span>
//   );
// }

const CustomTable = ({columns, options, data, onEditClick, onDeleteClick, onViewClick}) => {
  const updatedColumns = useMemo(() => columns, []);
  const updatedData = useMemo(() => data, []);
  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // Let's set up our default Filter UI
  //     Filter: SelectColumnFilter
  //   }),
  //   []
  // );
  const {
    getTableProps,
    headerGroups,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 10 },
    // defaultColumn,
  },
  useFilters, // useFilters!
  useGlobalFilter, // useGlobalFilter!
  usePagination,
  );

  return (
    <>
      <Row>
        {options && options.displayFilter && <Col md="3 mb-3">
          <FormGroup>
            <Label htmlFor="category">{"Filter By"}</Label>
            <Input type="select" name="category" className="form-control digits" defaultValue="Movie">
              <option>Select</option>
              <option>Movie</option>
            </Input>
          </FormGroup>
        </Col>}
        <Col md="5"></Col>
        {options && options.displaySearch && <Col md="4 mb-3">
          <FormGroup>
            <Label htmlFor="search">{"Search"}</Label>
            {/* <Input type="text" name="seach" className="form-control digits" placeholder="Search..." /> */}
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </FormGroup>
        </Col>}
      </Row>
      <div className="table-wrapper">
    <Table borderless {...getTableProps()}>
      <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column =>(
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      <tbody>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                // special column
                if (cell.column.id == 'Action') {
                  return (
                    <td className="media-edit" {...cell.getCellProps()}>
                      {onEditClick && <FontAwesomeIcon onClick={() => onEditClick(row.original)} icon={faPencil}></FontAwesomeIcon>}
                      {onViewClick && <FontAwesomeIcon onClick={() => onViewClick(row.original)} icon={faEye}></FontAwesomeIcon>}
                      {onDeleteClick && <FontAwesomeIcon onClick={() => onDeleteClick(true, row.original)} icon={faTrash}></FontAwesomeIcon>}
                    </td>
                  )
                }
                if (cell.column.meta && cell.column.meta.video_column) {
                  return (
                    <td {...cell.getCellProps()}>
                      <div className="align-middle item-detail">
                        <img className="cover m-r-15 align-top" src={row.original.thumbnailUrl ? PUBLIC_IMAGE_URL+row.original.thumbnailUrl : DEFAULT_IMAGE_THUMBNAIL} alt=""/>
                      </div>
                    </td>
                  )
                }

                if (cell.column.meta && cell.column.meta.user_defined) {
                  return (
                    <td {...cell.getCellProps()}>
                      <div className="align-middle user-defined-cell" title={JSON.stringify(row.original.user_defined)}>
                        {cell.render('Cell')}
                      </div>
                    </td>
                  )
                }

                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
                
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
    
    </div>
      <div className="pagination">
        <Pagination aria-label="..." className="nav-pagination custom-table">
          <PaginationItem disabled={!canPreviousPage}><PaginationLink  onClick={() => gotoPage(0)}>{"First"}</PaginationLink></PaginationItem>
          <PaginationItem disabled={!canPreviousPage}><PaginationLink  onClick={() => previousPage()}>{"Previous"}</PaginationLink></PaginationItem>
          <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => nextPage()}>{"Next"}</PaginationLink></PaginationItem>
          <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageCount - 1)}>{"Last"}</PaginationLink></PaginationItem>
        </Pagination>
      </div>
    </>
  )
};

export default CustomTable;