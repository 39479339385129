import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';

export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const uploadAudio = (formValues, callback, audio) => (dispatch, getState) => {
  // create a formData
  const userDefined = {};
  userDefined.merchant_name = formValues.merchant_name;
  userDefined.merchant_logo = formValues.merchant_logo;
  userDefined.product_name = formValues.product_name;
  userDefined.product_image_url = formValues.product_image_url;
  userDefined.redirect_url = formValues.redirect_url;
  userDefined.timestamp = formValues.timestamp;
  let data = new FormData();
  data.append('audio', audio);
  data.append('title', formValues.title);
  data.append('bucket_id', 19017);
  data.append('user_defined', JSON.stringify(userDefined));

  // title
  // bucket_id - 19017
  // audio
  // user_defined

  //options
  const options = {
    onUploadProgress: (progressEvent) => {
      const {
        total,
        loaded
      } = progressEvent;
      let percentage = Math.floor((loaded * 100)/total);
      if (percentage == 100) {
        callback(0);
      } else {
        callback(percentage);
      }
    }
    // headers: {
    //   'authorization': userData && userData.token,
    // }
  };

  //add a loader to upload button
  dispatch(dataChanged({loading: true}));

  axios.post([constants.AUDIO_UPLOAD_API], data, options)
  .then(function (response) {
    const data = response.data;
    if(data.errorMessage) {
      dispatch(dataChanged({
        [constants.AUDIO_DATA]: data.errorMessage,
        loading: false
      }));
    } else {
      dispatch(dataChanged({
        [constants.AUDIO_DATA]: data,
        loading: false,
      }));
    }

    dispatch(boundSuccess({loading: false}));
  })
  .catch(function (error) {
    dispatch(dataChanged({
      [constants.AUDIO_DATA]: error.message,
      loading: false,
    }));
    
    // dispatch(boundError({data: error, loading: false}));
  });
}

export const getCategoies = () => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  axios.get([constants.CATEGORY_API],
    {
      // headers: {
      //   // 'Access-Control-Allow-Origin': '*',
      //   'authorization': userData && userData.token,
      // },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const categories = data.responseData;
      const subCategories = categories;
      // update sub-category array
      dispatch(dataChanged({
        [constants.CATEGORIES]: categories,
        loading: false
      }));
    })
    .catch(function (error) {
      const { status } = error.response;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}

const beforeSubmit = (values, state) => {
  let updatedValues = {};

  updatedValues.title = values.title;
  // updatedValues.category = values.category;
  // updatedValues.subcategory = values.subcategory;
  updatedValues.categoryItemId = state.categoryItemId || '';
  updatedValues.album = values.album;
  updatedValues.artist = values.artist;
  updatedValues.album_artist = values.album_artist ? values.album_artist.split(',') : [];
  updatedValues.publisher = values.publisher;
  updatedValues.composer = values.composer;
  updatedValues.language_id = values.language_id;
  updatedValues.genre = values.genre ? values.genre.split(',') : [];
  updatedValues.description = values.description;
  updatedValues.moods = values.moods ? values.moods.split(',') : [];

  return updatedValues;
}

export const createMedia = (data, callbackFn) => (dispatch, getState) => {
  const currentState = getState();

  const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || null;
  const updatedData = beforeSubmit(data, currentState.mediaManagement);
  // http post request
  dispatch(dataChanged({loading: true, authenticatedSession: true}));
  
  axios.post([constants.ADD_MEDIA_API], updatedData,
    {
      headers: {
        'authorization': userData && userData.token,
        'Access-Control-Allow-Origin': '*',
      },
    })
  .then(function (response) {
    const responseData = response.data;
    if (responseData.errorMessage) {
      dispatch(
        dataChanged({
          [constants.MEDIA_DATA]: responseData.errorMessage,
          loading: false
        })
      )
    } else {
      dispatch(
        dataChanged({
          [constants.MEDIA_DATA]: responseData,
          [constants.MEDIA_ID]: responseData.responseData[0].mediaId,
          loading: false
        })
      );
      callbackFn(data);// form values need to be passed
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
    const { status } = error.response;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({loading: false, authenticatedSession: false}));
    } else {
      dispatch(dataChanged({loading: false}));
    }
    // dispatch(boundError({data: error, loading: false}));
  });
}


export const updateCategoryItemID = (categoryId) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.CATEGORY_ITEM_ID]: categoryId
    })
  )
}

export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_DATA]: {},
      [constants.IMAGE_DATA]: {},
      [constants.VIDEO_DATA]: {},
      [constants.AUDIO_DATA]: {},
      [constants.MEDIA_ID]: '',
      [constants.IMAGE_UPLOADING]: false,
      [constants.VIDEO_UPLOADING]: false,
      [constants.AUDIO_UPLOADING]: false,
      [constants.AUDIO_KEY]: '',
      [constants.VIDEO_KEY]: '',
      [constants.IMAGE_KEY]: ''
    })
  )
}

