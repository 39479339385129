import PropTypes from 'prop-types';
import React from 'react';

export const MediaDeleteForm = (props) => {
  console.log(props);
  return (
    <div>MediaDeleteForm</div>
  )
}

MediaDeleteForm.propTypes = {
  delete: PropTypes.bool
}

export default (MediaDeleteForm)