import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from './pages/authProvider';
import Layout from './pages/layout';
import RequireAuth from './pages/RequireAuth';

import {
  Nav,
  Login,
  ForgotPassword,
  MediaManagement,
  MediaList,
  Sidebar,
} from './pages';

import { configureStore } from './store';

import './style.scss';

export const store = configureStore();
const session = sessionStorage.getItem('authToken');

const App = () => {
  return (
    <AuthProvider> {/* it might go to layout */}
      <Provider store={store}>
        <Router>
          <Routes>
            <Route>
              {/* <Route path="/" element={<Login />} /> */}
              <Route path="/" element={<MediaList />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="media-management" element={<MediaManagement />} />
              <Route path="media-list" element={<MediaList />} />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </AuthProvider>
  )
}

export const Home = () => {
  return (
    <>
      <h2>Home Page</h2>
    </>
  )
}

export default App;