import React, {PureComponent, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateCategoryItemID,
} from '../../../ducks/media-management/index';

import {
  FormGroup,Label,Input,
} from 'reactstrap';


class MediaItemDropdown extends PureComponent {
  componentDidUpdate() {
    const {
    actions
  } = this.props;
  // update category item id
  const selectedValue = this.props.input.value;
  const mediaCategories = this.props.meta.data;
  
  const updatedCategories = mediaCategories.filter((el) => el.name == selectedValue);
  if (updatedCategories.length > 0) {
    const mediaItemID = updatedCategories[0].id;
    actions.updateCategoryItemID(mediaItemID);
  }
}

  render() {
    return (
      <>
        <FormGroup>
          <Label htmlFor={this.props.input.name}>{this.props.label}</Label>
          <Input type="select" name={this.props.input.name} value={this.props.input.value} onChange={this.props.input.onChange} className="form-control digits">
            <option>{"Select"}</option>
            {
            this.props.meta.data.map((el)=> {
              return <option key={el.id ? el.id : el} id={el.id ? el.id : el}>{el.name ? el.name : el}</option>
            })
            }
          </Input>
        </FormGroup>
        {(this.props.meta.error || this.props.meta.submitError) && this.props.meta.touched && (
          <span className="error-text">{this.props.meta.error || this.props.meta.submitError}</span>
        )}
      </>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    updateCategoryItemID,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(null, mapDispatchToProps)(MediaItemDropdown);

