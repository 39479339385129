import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as loginSelectors from '../../ducks/login/selectors';
import {
  login
} from '../../ducks/login/index';
import FORGOT_PWD_IMAGE from '../../images/forgot-password-frent-img.jpg';

export class ForgotPassword extends React.Component {
  static propTypes = {
    username: PropTypes.string,
  }
  static defaultProps = {
    username: '',
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    const {
      username,
      password,
      actions,
    } = this.props;
    console.log('logging in!!');
    actions.login(username, password);
  }
  render() {
    const {
      username,
    } = this.props;

    console.log('forgot password props', this.props);
    return (
      <>
        <div className="wrapper">
          <main className="authentication-content">
            <div className="container-fluid">
            <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-6 d-flex align-items-center justify-content-center border-end">
                  <img src={FORGOT_PWD_IMAGE} className="img-fluid" alt="" />
                </div>
                <div className="col-lg-6">
                  <div className="card-body p-4 p-sm-5">
                    <h5 className="card-title">Forgot Password?</h5>
                    <p className="card-text mb-5">Enter your registered email ID to reset the password</p>
                    <form className="form-body">
                        <div className="row g-3">
                          <div className="col-12">
                            <label htmlFor="inputEmailid" className="form-label">Email id</label>
                            <input type="email" className="form-control form-control-lg radius-30" id="inputEmailid" placeholder="Email id" />
                          </div>
                          <div className="col-12">
                            <div className="d-grid gap-3">
                              <button type="submit" className="btn btn-lg btn-primary radius-30">Send</button>
							                <Link to="/login" className="btn btn-lg btn-light radius-30">Back to Login</Link>
                            </div>
                          </div>
                        </div>
                    </form>
                 </div>
                </div>
              </div>
            </div>
          </div>
            </div>
        </main>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.login,
  data: loginSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    // login,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
