export const REDUCER_KEY = 'login';
import { BASE_URL } from './../../config';
export const DATA = 'data';
export const USERNAME = 'username';
export const PASSWORD = 'password';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const AUTH_TOKEN = 'authToken';
export const LOGIN_API = `${BASE_URL}/user/login`;
export const LOGOUT_MSG = 'logoutMessage';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [USERNAME]: '',
  [PASSWORD]: '',
  [DATA]: {},
  [AUTHENTICATED_SESSION]: false,
  [AUTH_TOKEN]: '',
  [LOGOUT_MSG]: '',
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
