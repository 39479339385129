import React from 'react'
import {
  Input,
} from 'reactstrap';

function FormInput(props) {
  return (
    <>
      <Input
        className={`form-control ${props.input.name}`}
        value={props.input.value}
        name={props.input.name}
        placeholder={props.placeholder}
        onChange={props.input.onChange} />
      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
      )}
    </>
  )
};

export default FormInput;
