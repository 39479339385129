import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import AuthContext from '../authProvider';

export const Header = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSignOut = () => {
    auth.signout((response) => {
      console.log('logout response', response);
      navigate('/' , { replace: true });
    });
  };

  return (
    <header className="top-header">        
      <nav className="navbar navbar-expand gap-3">
        {/* <div className="mobile-toggle-icon fs-3">
          <i className="bi bi-list"></i>
        </div> */}
        {/* <div className="top-navbar-right ms-auto">
          <Button onClick={handleSignOut} variant="outline-danger">Sign Out</Button>
        </div> */}
      </nav>
    </header>
  )
};

export default Header;